import { Search, Calendar, User, MessageCircle, Info, Network, UserCircle } from "lucide-react";
import { DiscordIcon } from "~/components/icons/DiscordIcon";
import { cn } from "~/lib/utils";
import { useState } from "react";
import type { Navigator } from "@remix-run/router";
import type { Location } from "@remix-run/router";

export type FooterIconType =
    | 'explore'
    | 'about'
    | 'support'
    | 'my-events'
    | 'login'
    | 'account'
    | 'my-networks';

interface FooterIcon {
    id: FooterIconType;
    icon: React.ElementType;
    label: string;
    route: string;
}

const AVAILABLE_ICONS: Record<FooterIconType, { icon: React.ElementType; label: string }> = {
    'explore': { icon: Search, label: "Explore" },
    'about': { icon: Info, label: "About" },
    'support': { icon: DiscordIcon, label: "Support" },
    'my-events': { icon: Calendar, label: "My Events" },
    'login': { icon: User, label: "Log in" },
    'account': { icon: UserCircle, label: "Account" },
    'my-networks': { icon: Network, label: "Networks" }
} as const;

interface IconFooterProps {
    icons: Array<{
        id: FooterIconType;
        route: string;
    }>;
    selectedIcon?: FooterIconType;
    onIconSelect?: (id: FooterIconType) => void;
    navigate: Navigator;
    location: Location;
}

export function IconFooter({
    icons,
    selectedIcon,
    onIconSelect,
    navigate,
    location
}: IconFooterProps) {
    const [isTransitioning, setIsTransitioning] = useState(false);

    const handleClick = (route: string, id: FooterIconType) => (e: React.MouseEvent) => {
        e.preventDefault();

        console.log(location.pathname, route)
        if (location.pathname === route) return;

        console.log("transitioning")
        setIsTransitioning(true);
        setTimeout(() => {
            window.location.href = route;
            onIconSelect?.(id);
        }, 200);
    };

    const TabItem = ({
        id,
        icon: Icon,
        label,
        route
    }: FooterIcon) => (
        <a
            className="z-10 flex flex-col items-center cursor-pointer"
            onClick={handleClick(route, id)}
            href={route}
        >
            <Icon
                className={cn(
                    "h-6 w-6 transition-colors duration-500",
                    selectedIcon === id
                        ? "text-blue-400"
                        : "text-muted-foreground"
                )}
            />
            <span className={cn(
                "text-xs font-medium mt-1.5 transition-colors duration-500",
                selectedIcon === id && "text-blue-400"
            )}>
                {label}
            </span>
        </a>
    );

    const footerIcons = icons.map(({ id, route }) => ({
        id,
        route,
        icon: AVAILABLE_ICONS[id].icon,
        label: AVAILABLE_ICONS[id].label
    }));

    return (
        <>
            {isTransitioning && (
                <div className="fixed inset-0 bg-background pointer-events-none" />
            )}
            <div className="z-12 fixed bottom-0 left-0 right-0 border-t bg-whit-100 bg-background">
                <div className="flex justify-around items-center p-3 max-w-md mx-auto">
                    {footerIcons.map((icon) => (
                        <TabItem
                            key={icon.id}
                            {...icon}
                        />
                    ))}
                </div>
            </div>
        </>
    );
} 